import { Injectable } from '@angular/core';
import { CommonData } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class CommonDatasService extends BaseService<CommonData> {
    constructor() {
        super('CommonData');
    }
}
