declare var bootstrap: any;
import {
  Component,
  OnInit,
  Injectable,
  HostListener,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event,
  RouteConfigLoadStart,
} from "@angular/router";
import { HelperService } from "./shared/helperService";
import { LookupService } from "./shared/lookupService";
import { AppInjector } from "../app-injector.service";
import { LoginsService } from "./core/service";
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { BaseService } from "./sharedBase/baseService";

declare var Tawk_API: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  showLoadingIndicator = true;
  menuActive = true;
  newsActive = false;
  isAdmin = false;
  isUserLoggedIn = false;
  loadIntialize = false;
  isMenuVisible: boolean = false;
  isOverlayVisible: boolean = false;
  loadComponent = false;

  loggedInUser: any;
  currentRoute: string;
  activeMenu: string = "";
  isWindowScroll: boolean = false;
  isLeftMenuFixed: boolean = false;
  isRightPartFixed: boolean = false;
  showMobileView: boolean = false;
  isMobSearchVisible: boolean = false;
  isMobProfileVisible: boolean = false;
  toggleNotification: boolean = false;
  isBlockVisible: boolean = true;
  isLoaderVisible: boolean = true;
  hideTooltip: boolean = false;
  userName: string;
  sidebar: boolean = true;
  url: any;
  items: MenuItem[];
  loginLoadingComplete = false;

  activeDashboard: boolean = false;
  isSidebarActive = true;

  constructor(
    private translate: TranslateService,
    public router: Router,
    public helperService: HelperService,
    public lookupService: LookupService,
    private renderer: Renderer2,
    private primengConfig: PrimeNGConfig

  ) {
    translate.setDefaultLang("en");
    BaseService.setHelperService(helperService);
    const injector = AppInjector.getInjector();
    this.helperService.loginChange.subscribe((loginType) => {
      console.log("login change app", loginType);
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    });

    this.router.events.subscribe((routerEvent: Event) => {
      this.isMobSearchVisible = false;
      this.isMobProfileVisible = false;
      this.toggleNotification = !this.toggleNotification;
      if (routerEvent instanceof RouteConfigLoadStart) {
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
      }
      if (routerEvent instanceof NavigationStart) {
        ////console.log('router.events', routerEvent.url);
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
        let currentUrl = routerEvent.url.substr(
          routerEvent.url.indexOf("/") + 1
        );
        this.currentRoute = currentUrl;
        this.activeMenu = this.getActiveMenu(routerEvent.url);
      }

      if (
        routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError
      ) {
        setTimeout(() => {
          this.showLoadingIndicator = false;
        }, 500);

        setTimeout(() => {
          this.isLoaderVisible = false;
        }, 2000);

        setTimeout(() => {
          const url = this.router.routerState.snapshot.url.toLowerCase();
          if (url.indexOf("/foundation/") >= 0) {
            this.helperService.scrollToSection("child-foundation");
          } else {
            this.helperService.scrollToSection("main-page");
          }
        }, 200);
      }
    });
    this.loadTawkTo();
  }

  getActiveMenu(url: string): string {
    if (url.toLowerCase().indexOf("platters") >= 0) return "platters";
    if (url.toLowerCase().indexOf("investment-report") >= 0)
      return "investment-report";
    if (url.toLowerCase().indexOf("mandates") >= 0) return "mandates";
    if (url.toLowerCase().indexOf("bseRegistrations".toLowerCase()) >= 0)
      return "bseRegistrations";
    if (url.toLowerCase().indexOf("mfUserOrders".toLowerCase()) >= 0)
      return "mfUserOrders";
    if (
      url.toLowerCase().indexOf("edExpenses".toLowerCase()) >= 0 ||
      url.toLowerCase().indexOf("edIncomes".toLowerCase()) >= 0
    )
      return "tracker";
    if (url.toLowerCase().indexOf("contact-us") >= 0) return "contact-us";

    return "";
  }
  ngOnInit() {
    this.primengConfig.ripple = true;
    setTimeout(() => {
      this.lookupService.getDataEnum("").subscribe((list) => {
        this.loadIntialize = true;
      });
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
      this.getUserInfo();
    }, 50);


    // GoogleAuth.initialize({
    //   clientId: '927555018564-ri84knhsc1bv29ki83ffa4msj42tt1vr.apps.googleusercontent.com',
    //   scopes: ['profile', 'email'],
    //   grantOfflineAccess: true,
    // });
    setTimeout(() => {
      this.loadIntialize = true;
      this.loadComponent = true;
    }, 1000);

    this.showMobileView = this.helperService.isMobile;

    if (!this.helperService.isMobile) {
      this.isMobSearchVisible = true;
    } else {
      this.isMobSearchVisible = false;
    }
    this.items = [
      {
        label: 'Options',
        items: [
          {
            label: 'Profile',
            icon: 'pi pi-refresh',
            command: () => {
              this.router.navigateByUrl('profile')
            }
          },
          {
            label: 'Settings',
            icon: 'pi pi-user',
            command: () => {
            }
          },
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
              this.logout();
            }
          }
        ]
      },

    ];
  }
  isCustomDashboard(): boolean {
    const url = this.router.url;
    return !(url == '/login' || url == '/patient/client' || url == '/patient/doctor' || url == '/login1' || url == '/admin/login' || url == '/login/dealer');

  }
  ngAfterViewInit(): void {
    // setTimeout(()=>{
    //   this.userName=this.helperService.loggedInUser.firstName;
    // },100)



  }
  getUserInfo(attempt: number = 0) {
    this.loggedInUser = this.helperService.getLoggedInUserInfo();
    console.log('getLoggedInUserInfo waiting', this.loggedInUser, attempt);
    if (attempt < 5) {
      if (!this.loggedInUser) {
        setTimeout(() => {
          this.getUserInfo(++attempt);
        }, 100);
      } else {
        this.isUserLoggedIn = this.helperService.isUserLoggedIn;
        this.isAdmin = this.helperService.isAdminUser;
        this.userName = this.helperService.loggedInUser.firstName;
        this.loginLoadingComplete = true;
      }
    }

    if (attempt >= 5) {
      this.loginLoadingComplete = true;
    }
  }
  goto(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      this.collapseSidebar();
    }
  }
  collapseSidebar() {
    // Manually trigger Bootstrap's collapse
    const sidebarMenu = document.getElementById('sidebarMenu');
    if (sidebarMenu) {
      const collapseInstance = new bootstrap.Collapse(sidebarMenu, {
        toggle: false
      });
      collapseInstance.hide();  // Hide the sidebar
    }
  }
  route(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
  }
  onMenuButtonClick(event: any) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

  logout() {
    const injector = AppInjector.getInjector();
    const loginSvc = injector.get(LoginsService);

    loginSvc.clearData();
    // this.endChatSection();
    this.helperService.logout();
    this.helperService.loginChange.next('login');
    this.helperService.routeNavigate("login");
    this.isAdmin = false;
  }

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible;
    this.isOverlayVisible = !this.isOverlayVisible;
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      this.isWindowScroll = true;
    } else {
      this.isWindowScroll = false;
    }


    if (
      document.body.scrollTop > 450 ||
      document.documentElement.scrollTop > 450
    ) {
      this.isLeftMenuFixed = true;
    } else {
      this.isLeftMenuFixed = false;
    }

    if (
      document.body.scrollTop > 1030 ||
      document.documentElement.scrollTop > 1030
    ) {
      this.isRightPartFixed = true;
    } else {
      this.isRightPartFixed = false;
    }
  }

  gotoTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  toggleMobileProfile(event: any): void {
    event.stopPropagation();
    this.isMobProfileVisible = !this.isMobProfileVisible;
  }

  toggleMobileSearch(event: any): void {
    event.stopPropagation();
    this.isMobSearchVisible = !this.isMobSearchVisible;
  }

  @HostListener("click", ["$event"])
  onClick(event: any): void {
    this.isMobProfileVisible = false;
    this.isMobSearchVisible = false;
    this.toggleNotification = false;
  }
  @HostListener('window:scroll', ['$event']) fun() {
    if (window.innerWidth < 760) {
      this.hideTooltip = true;
    } else {
      this.hideTooltip = false;
    }
  }
  // For Style
  closeSideMenu() {
    this.sidebar = !this.sidebar;
  }

  toogleSidebar(event: any) {
    this.activeDashboard = event;
  }

  // endChatSection() {
  //   // if (Tawk_API && Tawk_API.endChat) {
  //   //   Tawk_API.hideWidget();
  //   //   Tawk_API.endChat();
  //   // }

  //   if (Tawk_API) {
  //     // End the chat to clear chat history and session data
  //     if (Tawk_API.endChat) {
  //       Tawk_API.endChat();
  //     }
  //     // Hide the widget to ensure it does not appear again
  //     if (Tawk_API.hideWidget) {
  //       Tawk_API.hideWidget();
  //     }
  //   }

  //   // Remove the Tawk.to script from the DOM
  //   const tawkScript = document.getElementById('tawkto-script');
  //   if (tawkScript) {
  //     tawkScript.remove();
  //   }

  //   // Remove any Tawk.to iframe that might be left in the DOM
  //   const tawkIframe = document.querySelector('iframe[src*="tawk.to"]');
  //   if (tawkIframe) {
  //     tawkIframe.remove();
  //   }

  //   // Optional: Delete Tawk_API from the window object
  //   // delete (window as any).Tawk_API;
  // }

  loadTawkTo() {
    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/672359b74304e3196adb1665/1ibh1ctei';
    script.async = true;
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    script.id = 'fc4cc015603a27f9613e1c066268fb10356884bd';
    document.body.appendChild(script);
  }
}
