import { Injectable } from '@angular/core';
import { Document } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class DocumentsService extends BaseService<Document> {
    constructor() {
        super('Document');
    }
}
